(function ($) {
  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context, settings) {
      $('div.email-signup').find('form').bind('submit', function (submitEvt) {
    	 submitEvt.preventDefault();
      });
    }
  };
})(jQuery);

function sign_up_with_email(dest) {
  dest = dest || '';
  if (/^\/.+$/.test(dest)) {
    dest = dest.replace(/^(\s|\/)+/, '');
  }
  if (/^(?!http|\/).+$/.test(dest)) {
    dest = Drupal.settings.basePath + dest;
  }
  var email = document.getElementsByName('mobile_email_address');
  var emailvalue = email[0].value;

  if (emailvalue != $('#default').val() && emailvalue != '') {
    dest = dest + '?email=' + escape(emailvalue);
    $('.email-signup__error').addClass('hidden');
    window.location.href = dest;
  } else {
    $('.email-signup__error').removeClass('hidden');
  }

  return false;
}
